import React from "react";
import { Order } from "../helpers/api";
import { sendNotification } from "../helpers/notification";
// import { isEmpty } from "lodash";

export const OrderContext = React.createContext({});

export class OrderProvider extends React.Component {
  state = {
    updateState: async (key, val) => {
      await this.setState((state) => {
        state[key] = val;
        return state;
      })
    },

    getInfo: async (hash) => {
      await Order.getInfo(hash)
        .then((res) => {
          if (res.body.status !== 'PENDING') {
            localStorage.removeItem('marked');
          }
          this.setState({ order: res.body });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
        });
    },

    getCard: async (hash) => {
      let params = {
        hash, 
        cardProvider: this.state.bank
      }
      await Order.getCard(params)
      .then((res) => {
        this.setState({ order: { ...this.state.order, ...res.body} });
        sendNotification("success", "Реквизиты получены", "Сделайте перевод средств на полученую карту.");
      })
      .catch((error) => {
        sendNotification("warning", "Нету свободных карт.", "Попробуйте повторить через несколько минут.");
        throw Error('Нету свободных карт.')
      });
    },

    markAsPaid: async (uuid) => {
      await Order.markAsPaid(uuid)
      .then((res) => {
        this.setState({ marked: true });
        localStorage.setItem('marked', true);
        sendNotification("success", "Заявка отмечена как оплаченая", "Ожидаем подтверждение по зачислению на карту.");
      })
      .catch((error) => {
        throw Error(error.message);
      });
    },

    clean: async () => {
      return this.setState({
        hash: null,
      });
    }
  };

  render() {
    return (
      <OrderContext.Provider value={this.state}>
        {this.props.children}
      </OrderContext.Provider>
    );
  }
}
