// native libs
import React from "react";
import ReactDOM from 'react-dom/client';
// commponents
import App from "./App";
// context
import { OrderProvider } from "./context/orders-context";
// libs
import { ReactNotifications } from 'react-notifications-component';
import "react-notifications-component/dist/theme.css";
// system
import * as serviceWorker from "./serviceWorker";

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
	<>
		<OrderProvider>
			<ReactNotifications />
			<App />
		</OrderProvider>
	</>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
