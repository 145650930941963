import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import Loader from "../components/Loader";
import Card from "../components/Card";

import { sendNotification } from "../helpers/notification";

import { OrderContext } from "../context/orders-context";

moment.locale('ru'); 

const Order = (props) => {
  const [loading, setLoading] = React.useState(true);
  const { uuid } = useParams();
  const OrderCunsumer = useContext(OrderContext);

  useEffect(() => {
    OrderCunsumer.getInfo(uuid);

    return () => OrderCunsumer.clean();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(OrderCunsumer?.order?.id)) {
      let marked = localStorage.getItem('marked');
      if (marked === "true") {
        OrderCunsumer.updateState('marked', true);
      }

      setLoading(false);
    }
  }, [OrderCunsumer.order]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (OrderCunsumer.marked === true) {
      setLoading(false);
    }
  }, [OrderCunsumer.marked]);  // eslint-disable-line react-hooks/exhaustive-deps

  const renderActive = (val) => {
    if (val === OrderCunsumer.bank) {
      return 'active'
    } else {
      return;
    }
  }

  const getBank = (value) => {
    switch (value) {
      case '1':
        return 'sberbank';
      case '2':
        return 'tinkoff';
      case '3':
        return 'visa/master';
      case '4':
        return 'yandex';
      default:
        return;
    }
  }

  const handler = (event) => {
    OrderCunsumer.updateState('bank', getBank(event.target.value));
  };

  const submit = async () => {
    if (!['sberbank', 'tinkoff', 'visa/master', 'yandex'].includes(OrderCunsumer.bank)) {
      sendNotification("danger", "Вы не выбрали банк ⚠️", "Выберите банк с которого будете делать оплату.");
      return;
    }
    setLoading(true);
    return OrderCunsumer.getCard(uuid).catch((e) => {
      setLoading(false);
    });
  }  

  const markPaid = async () => {
    setLoading(true);
    return OrderCunsumer.markAsPaid(uuid).catch((e) => {
      setLoading(false);
      sendNotification("danger", "Ошибка ⚠️", e.message);
    });
  }

  return (
    <>
      <div className="iphone">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="text-center">
            <img src={require('../images/logo.png')} width={'250'} alt="" />
            </div>
            <header className="header">
              <h1>Оплата заявки #{OrderCunsumer?.order?.id}</h1>
            </header>
            <hr/>

            { OrderCunsumer.order.status === 'CREATED' && !OrderCunsumer.order.cardNumber &&
              <form className="form" onSubmit={(e) => e.preventDefault()}>
                <fieldset>
                  <legend>1. Выберите метод оплаты: 👉 </legend>

                  <div className="form__radios">
                    <div className={`form__radio ${renderActive('sberbank')}`}>
                      <label htmlFor="visa">
                        <img width={'130px'} src={require('../images/sber.png')} alt="" />  
                      </label>
                      <input onChange={(e) => handler(e)} id='visa' value={1} name="bank" type="radio" />
                    </div>

                    <div className={`form__radio ${renderActive('tinkoff')}`}>
                      <label htmlFor="paypal">
                        <img width={'150px'} src={require('../images/tinkoff.png')} alt="" />
                      </label>
                      <input onChange={(e) => handler(e)} id='paypal' value={2} name="bank" type="radio" />
                    </div>

                    <div className={`form__radio ${renderActive('visa/master')}`}>
                      <label htmlFor="mastercard">
                        <img width={'160px'} src={require('../images/vm2.png')} alt="" />
                      </label>
                      <input onChange={(e) => handler(e)} id='mastercard' value={3} name="bank" type="radio" />
                    </div>
                    <div className={`form__radio ${renderActive('yandex')}`}>
                      <label htmlFor="yandex">
                        <img width={'160px'} src={require('../images/yandex.png')} alt="" />
                      </label>
                      <input onChange={(e) => handler(e)} id='yandex' value={4} name="bank" type="radio" />
                    </div>
                  </div>
                </fieldset>
                <div className="my-4">
                  <hr/>
                </div>
                <div className="text-center mb-4">
                <strong>💰 Сумма к оплате: {OrderCunsumer.order.amount} RUB.</strong>
                </div>

                <div className="mb-4">
                  <button className="button button--full" onClick={submit}>
                    <strong>2. Получить реквизиты 👉</strong>
                  </button>
                </div>

                <hr />

                <p className="text-muted mb-0" style={{ fontSize: '14px'}}>
                  1. <strong>Выберите банк</strong>, с которого хотите <strong>совершить</strong> оплату. <br />
                  2. Получите реквизиты для оплаты. <br />
                  3. Сделайте оплату с Вашего банка на полученые реквизиты. <br />
                  4. <strong>После оплаты,</strong> нажмите на кнопку "Я оплатил заявку."
                </p>
              </form>
            }

            { OrderCunsumer.order.status === 'PENDING' && OrderCunsumer.marked !== true && !isEmpty(OrderCunsumer.order.cardNumber) && 
              <div className="text-center">
                <h4>3. Произведите оплату на карту: 👉</h4>
                <hr />
                <Card 
                  bank={OrderCunsumer.order.cardProvider}
                  card={OrderCunsumer.order.cardNumber}
                  amount={OrderCunsumer.order.amount}
                />
                <hr />
                <div className="mb-3">
                  <button className="button button--full" onClick={markPaid}>
                    <strong>4. Я оплатил заявку</strong>
                  </button>
                </div>

                {OrderCunsumer?.order?.pendingAt && (
                  <div className="countdownTimer">
                    <p className="text-muted mt-0 mb-2">Осталось времени</p>
                    <CountdownCircleTimer
                      isPlaying
                      duration={900}
                      initialRemainingTime={moment(OrderCunsumer.order.pendingAt).add(15, 'minutes').diff(moment(), 'seconds')}
                      colors={['#0c8842', '#FFBF00', '#FF0000', '#FF0000']}
                      colorsTime={[900, 720, 240, 0]}
                      size={80}
                      strokeWidth={5}
                      updateInterval={1}
                      isSmoothColorTransition={false}
                    >
                      {({ remainingTime }) => {
                          const minutes = Math.floor(remainingTime / 60)
                          const seconds = remainingTime % 60
                          return <div className="text-center"><strong>{minutes}:{seconds}</strong><br /><span style={{fontSize: '12px'}}>минут</span></div>
                        }
                      }
                    </CountdownCircleTimer>
                  </div>
                )}
              </div>
            }

            { OrderCunsumer.order.status === 'PENDING' && OrderCunsumer.marked === true && 
              <>
                <div className="alert alert-success text-center mb-3">
                  <h4>Вы отметили заявку как "Оплаченая"</h4>
                  <hr />
                  Ожидаем подтверждения по заходу средств. Обично занимает 1-2 минуты.
                </div>

                <div className="text-center">
                  <button className="button button--full" onClick={() => window.location.reload(false)}>
                    Обновить страницу ⏱
                  </button>
                </div>
              </>
            }

            { OrderCunsumer.order.status === 'SUCCESS' && 
              <div className="alert alert-success text-center">
                <h4>Заявка на пополнение <strong>успешно закрыта.</strong> 👌</h4>
                <hr />
                Средства поступят на баланс в течение 1 минуты.
              </div>
            }

            { OrderCunsumer.order.status === 'EXPIRED' && 
              <div className="alert alert-warning text-center">
                <h4>Заявка на пополнение <strong>просрочена.</strong> 🚫</h4>
                <hr />
                Время на пополнение вышло. Вы должны создать новую заявку в своей системе.
              </div>
            }
            
          </>
        )}
        
      </div>
    </>
  );
};

export default Order;
