import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import  secureLocalStorage  from  "react-secure-storage";
// Scss
import "./scss/style.scss";
// Pages admin
import Order from "./pages/Order";
import NoRoute from "./pages/NoRoute";

export default class App extends React.Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/order/:uuid" component={Order} />
          <Route component={NoRoute} />
        </Switch>
      </Router>
    );
  }
}
