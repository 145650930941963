import superagentPromise from "superagent-promise";
import _superagent from "superagent";

const superagent = superagentPromise(_superagent, global.Promise);
const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;

const handleErrors = (err) => {
  if (err && err.response && err.response.status === 401) {
    console.log(err);
  }
  return err;
};

const requests = {
  get: async (url, query) =>
    await superagent
      .get(`${API_URL}${url}`)
      .query(query)
      .end(handleErrors),
  post: (url, body) =>
    superagent
      .post(`${API_URL}${url}`, body)
      .end(handleErrors),
  // put: (url, body) =>
  //   superagent.put(`${API_URL}${url}`, body).use(tokenPlugin).end(handleErrors),
  // patch: (url, body, root = API_URL) =>
  //   superagent.patch(`${root}${url}`, body).use(tokenPlugin).end(handleErrors),
};

export const Order = {
  getInfo: async (hash) => await requests.get(`/order/details/${hash}`),
  getCard: async (params) => await requests.post("/order/details", params),
  markAsPaid: async (hash) => await requests.post("/order/marked/paid", { hash }),
};