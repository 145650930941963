import React from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Card = ({ card, bank, amount}) => {

  const [copyStatus, setCopyStatus] = React.useState({});

  return (
    <div className="bankcard">
    <div className="bankcard-inner">
      <div className="bankcard-front">
        <div className="bankcard-bg"></div>
        <div className="bankcard-glow"></div>
        
        <div className="bankcard-contactless">
          <svg xmlns="http://www.w3.org/2000/svg" width="46" height="56">
            <path
              fill="none"
              stroke="#f9f9f9"
              strokeWidth="6"
              strokeLinecap="round"
              d="m35,3a50,50 0 0,1 0,50M24,8.5a39,39 0 0,1 0,39M13.5,13.55a28.2,28.5
0 0,1 0,28.5M3,19a18,17 0 0,1 0,18"
            />
          </svg>
        </div>
        <div className="bankcard-chip"></div>
        <div className="bankname">Банк: {bank}</div>
        <div className="bankcard-number">
          {card}
          <CopyToClipboard text={card}
              onCopy={() => {
                setCopyStatus({ copied: true })
                setTimeout(() => {
                  setCopyStatus({ copied: false })
                }, 700);
              }}
            >
              <i className="fa-solid fa-copy ml-3"></i>
            </CopyToClipboard>
        </div>
        
        <div className="amount">
          {copyStatus.copied ? (
            `Скопировано`
          ): (
            `сумма: ${amount} RUB.`
          )}
        </div>
      </div>
    </div>
  </div>
  );
};

export default Card;
