import React from "react";

const Loader = () => {
  return (
    <div className="loader center text-center" style={{ top: '50%', position: 'relative', transform: 'translateY(-25%)' }}>
      <img src={require("../images/loader.gif")} className="mb-1" width={'100px'} alt="" />
      <h4 className="text-muted mt-1">Загрузка...</h4>
    </div>
  );
};

export default Loader;
